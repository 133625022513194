import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'

const ContactForm = () => {


    return (
        <section className="py-10 bg-white sm:py-16 lg:py-10">
            <div className="w-11/12 mx-auto">
                <div className='block lg:grid grid-cols-12 gap-8  mb-10'>
                    <div className='col-span-12 lg:col-span-5 mb-8 lg:mb-0'>
                        <Slide>

                            <p className='text-primary text-[13px] font-normal font-dmsans mb-5'>GET IN TOUCH</p>
                            <h2 className=" text-[32px] leading-[38.82px] md:leading-[54.59px] text-[#353535] md:text-[45px]  font-mn_semibold">Ready to transform your business today?</h2>
                            <p className="leading-[20.83px]  mt-5 text-[16px] font-normal text-[#353535] font-dmsans mb-8  ">Have questions, comments, or inquiries about our consulting services? We're here to help. </p>
                            <div className='bg-[#F6F6F6] p-5'>
                                <Fade cascade>

                                    <div className='flex  gap-3 pb-5 border-b border-dashed border-[#B5B8C1]'>
                                        <div className='mt-1'>
                                            <img src={'/images/location.svg'} alt='icon' />
                                        </div>
                                        <div className='flex-col flex gap-3'>
                                            <p className='font-dmsans'>
                                                Drop us an email at</p>
                                            <div className='font-dmsans'>
                                                <p className='text-primary  font-bold  '>info@bayaconsult.com</p>
                                                <p className='text-primary  font-bold'>oroluwatobi.okeyinka@bayaconsult.com</p>

                                            </div>
                                        </div>


                                    </div>
                                    <div className='flex  gap-3 py-5 border-[#B5B8C1]'>
                                        <div className='mt-2'>
                                            <img src={'/images/phone.svg'} alt='icon' />
                                        </div>
                                        <p className='font-dmsans'>
                                            Prefer to speak directly to one of our consultants?
                                            <br />
                                            Give us a call at <span className='text-primary  font-bold'>+2348102878826</span> during our <br />business hours:<br />
                                            <span className='text-primary  font-bold'> Monday to Friday, 9:00 AM - 5:00 PM.</span>
                                        </p>

                                    </div>

                                    <div className='flex items-center gap-3 pt-5 hidden '>
                                        <div>
                                            <img src={'/images/location.svg'} alt='icon' />
                                        </div>
                                        <p className='font-dmsans'>
                                            or visit us at our office located at:
                                            <br />
                                            <span className='text-primary  font-bold'>123 Consulting Street Suite 101, City, State, ZIP Code
                                                <br />
                                                Country</span>
                                        </p>

                                    </div>
                                </Fade>


                            </div>
                        </Slide>

                    </div>
                    <div className='col-span-12 lg:col-span-7'>
                        <Slide direction='right'>
                            <form className="border border-[#CBCBCB] p-5 md:p-8 rounded-[25px] ">

                                <div className='grid grid-cols-2 mb-3 gap-3'>
                                    <div>
                                        <label className='text-[#868686] font-medium text-[14px]  w-full' for='first_name'>First name</label>
                                        <input id='first_name' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary' placeholder='First Name' />
                                    </div>
                                    <div>
                                        <label className='text-[#868686] font-medium text-[14px]  w-full' for='last_name'>Last name</label>
                                        <input id='last_name' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary' placeholder='Last Name' />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#868686] font-medium text-[14px]  w-full' for='email'>Email Address</label>
                                    <input id='email' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary' placeholder='account@email.com' />
                                </div>
                                <div className='mb-3'>
                                    <label className='text-[#868686] font-medium text-[14px]  w-full' for='company'>Company Name (Optional)</label>
                                    <input id='company' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary' placeholder='Name of company' />
                                </div>
                                <div className='mb-4'>
                                    <label className='text-[#868686] font-medium text-[14px]  w-full' for='message'>Type message</label>
                                    <textarea rows={7} id='message' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary' placeholder='Name of company' />
                                </div>
                                <div>
                                    <button className='flex-shrink-0 bg-primary text-white rounded-[7px] px-5 py-3  font-medium'>
                                        Send Message
                                    </button>
                                </div>

                            </form>
                        </Slide>




                    </div>
                </div>

            </div>

        </section>
    )
}

export default ContactForm