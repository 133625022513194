import React, { useState } from 'react'
import { Bounce } from 'react-awesome-reveal';
import { isMobile, isTablet } from 'react-device-detect';

const OurWork = () => {
    const [activeIndex, setActiveIndex] = useState(1)
    return (
        <section className="py-10 bg-white sm:py-16 lg:py-10">
            <div className="lg:w-11/12 mx-auto">

                <div className="">
                    <Bounce>
                        <p className='text-primary text-[13px] font-normal font-dmsans mb-5 text-center'>OUR WORK</p>
                        <h2 className=" font-bold leading-[38.82px] lg:leading-[54.59px] text-[#353535] text-[32px] lg:text-[45px]  font-mn_semibold text-center mx-auto lg:w-4/5 mb-6">We work with clients in different sectors to achieve impact</h2>
                    </Bounce>


                    <div className='my-4 pl-7 lg:pl-0'>
                        <div className="flex items-center overflow-scroll  lg:grid grid-col-12 lg:grid-flow-col gap-4 w-full ">

                            <div className={`w-[85%] lg:w-full transition-all flex-shrink-0 duration-50 1s ease-in-out forwards ${activeIndex === 1 ? ' 1s ease-in-out forwards lg:col-span-8 ' : 'lg:col-span-2 '} cursor-pointer h-[555px] rounded-[30px] work-container relative overflow-hidden work_1`} onClick={() => {
                                setActiveIndex(1)
                            }}>
                                <div className='absolute bottom-[30px] text-white flex gap-x-3 left-[10px]'>
                                    <div className='text-[23.69px] lg:text-[25px] font-mn_semibold text-white  w-6 lg:w-10   '>
                                        <p className='-rotate-90 whitespace-nowrap mt-28 md:mt-20 lg:mt-16'>Health Sector</p>
                                    </div>
                                    {
                                        (isMobile || activeIndex === 1) && <p className='text-white font-normal t-[14px] leading-[18.5px] lg:text-[16px] lg:leading-[20.83px]  font-dmsans w-[80%] md:w-[90%]'>HealthCo was a young startup offering enterprise tech to Hospitals and Pharmacies in Northern Nigeria. HealthCo approached BCS to help them execute on expansion and fundraising
                                            BCS worked with HealthCo to assess the market, build out financial projections, and expand into bigger markets in Nigeria. HealthCo’s work with BCS has formed the basis of a current fundraising round with Angel and Institutional investors.</p>
                                    }
                                </div>
                            </div>
                            <div
                                className={`w-[85%] lg:w-full transition-all flex-shrink-0 duration-[1000ms] ease-in-out ${activeIndex === 2 ? 'lg:col-span-8' : 'lg:col-span-2'} cursor-pointer h-[555px] rounded-[30px] work-container relative overflow-hidden work_2`}
                                onClick={() => {
                                    setActiveIndex(2);
                                }}
                            >
                                <div className='absolute bottom-[30px] text-white flex gap-x-3 left-[10px]'>
                                    <div className='text-[23.69px] lg:text-[25px] font-mn_semibold text-white w-6 lg:w-10'>
                                        <p className='-rotate-90 whitespace-nowrap mt-24 md:mt-20 lg:mt-[80px]'>Real Estate</p>
                                    </div>
                                    {(isMobile || activeIndex === 2) && (
                                        <p className='text-white font-normal text-[14px] leading-[18.5px] lg:text-[16px] lg:leading-[20.83px] font-dmsans w-[80%] md:w-[90%]'>
                                            Real Estate Co. was a young startup offering opportunities to Nigerians to invest in global Real Estate via fractional property ownership. Real Estate Co approached BCS to help them identify growth opportunities and raise funds. BCS worked with Real Estate Co to assess the market and the competitive landscape, build out a 10-year projection of the business, and develop a pitch deck and a playbook for capturing growth opportunities.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className={`mr-7 lg:pr-0 w-[85%] lg:w-full transition-all flex-shrink-0 duration-50 1s ease-in-out forwards ${activeIndex === 3 ? ' 1s ease-in-out forwards lg:col-span-8 ' : 'lg:col-span-2 '}  cursor-pointer h-[555px] rounded-[30px] work-container relative overflow-hidden work_3`} onClick={() => {
                                setActiveIndex(3)
                            }}>
                                <div className='absolute bottom-[30px] text-white flex gap-x-3 left-[10px]'>
                                    <div className='text-[23.69px] lg:text-[25px] font-mn_semibold text-white  w-6 lg:w-10   '>
                                        <p className='-rotate-90 whitespace-nowrap mt-[150px] md:mt-24 lg:mt-[100px]'>Fashion & Retail</p>
                                    </div>
                                    {
                                        (isMobile || activeIndex === 3) && <p className='text-white font-normal text-[14px] leading-[18.5px] lg:text-[16px] lg:leading-[20.83px]  font-dmsans w-[80%] md:w-[90%]'>FashionCo, a well-established business selling high-quality, modest fashion accessories to Nigerians, sought to expand into the retail fashion space through an investor partnership. They approached BCS for assistance. BCS helped FashionCo explore expansion possibilities by assessing the competitive landscape, providing a valuation estimate, developing a 10-year financial projection for the partnership, and strategizing for nationwide online shopping with recommendations such as conducting a pilot project in Lagos.</p>
                                    }
                                </div>
                            </div>
                            <div className={`mr-7 lg:pr-0 w-[85%] lg:w-full transition-all flex-shrink-0 duration-50 1s ease-in-out forwards ${activeIndex === 4 ? ' 1s ease-in-out forwards lg:col-span-8 ' : 'lg:col-span-2 '}  cursor-pointer h-[555px] rounded-[30px] work-container relative overflow-hidden work_4`} onClick={() => {
                                setActiveIndex(4)
                            }}>
                                <div className='absolute bottom-[30px] text-white flex gap-x-3 left-[10px]'>
                                    <div className='text-[23.69px] lg:text-[25px] font-mn_semibold text-white  w-6 lg:w-10   '>
                                        <p className='-rotate-90 whitespace-nowrap mt-[82px] md:mt-28 lg:mt-32'>Agriculture</p>
                                    </div>
                                    {
                                        (isMobile || activeIndex === 4) && <p className='text-white font-normal text-[14px] leading-[18.5px] lg:text-[16px] lg:leading-[20.83px]  font-dmsans w-[80%] md:w-[90%]'>AgriTech, an agricultural optimization service company in Africa, aimed to secure $5 million in funding to expand its operations. The company, which connects smallholder farmers with vital resources such as equipment, seeds, pesticides, fertilizers, and mechanized harvesting, approached BCS for assistance. BCS evaluated AgriTech's current operations, identified key opportunities for value addition, and developed a strategic roadmap for future growth. Additionally, BCS provided a valuation estimate, prepared an investor pitch deck, and offered recommendations on the best funding options for the business.</p>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default OurWork