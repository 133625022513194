import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'
import { isMobile } from 'react-device-detect';

const OurServices = () => {
    return (
        <section className="py-5 ">
            <div className=' our_services_section mb-[1300px] h-[620px] lg:mb-[150px] md:mb-[500px]'>
                <div className="w-11/12 mx-auto">
                    <div className='grid lg:grid-cols-12 gap-8 items-center py-16'>
                        <div className='col-span-6'>
                            <Slide direction='left'>
                                <p className='text-[#DEE7FF] text-[13px] font-normal font-dmsans mb-5 z-[99]'>OUR SERVICES</p>
                                <h2 className=" font-bold leading-[39.38px] md:!leading-[58.59px] text-white text-[32.47px] md:text-[45px]  font-mn_semibold  z-[99]">We offer a suite of professional services tailored to our clients' unique contexts</h2>
                            </Slide>


                        </div>
                        <div className='col-span-6'>
                            <Slide direction='right'>
                                <p className="lg:mt-5  text-[16.23px] leading-[21.14px] md:leading-[20.83px] md:text-[16px] font-normal text-white font-dmsans pb-4   z-[99]">We serve clients on the African continent across various industries including Technology, Financial Services, Real Estate, Healthcare, Fashion & Beauty and others</p>

                            </Slide>

                        </div>
                    </div>
                </div>
                <div className='lg:absolute -bottom-[200px] md:-bottom-[300px] lg:bottom-[150px]  md:h-[900px] lg:h-[70px] w-11/12 mx-auto right-0 left-0 '>
                    <div className='w-full flex items-center justify-center '>
                        <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4 w-full'>
                            <Fade cascade>
                                <div className='h-[350px] bg-[#ECF1FF] p-3 rounded-[15px] w-full flex flex-col justify-between '>
                                    <div className=''>
                                        <img src={isMobile ? '/images/mo2.png' : '/images/s1.svg'} alt='services_1' className='h-[162px] w-full object-cover rounded-[8px]' />
                                    </div>
                                    <h5 className='text-[18px] font-semibold text-primary font-mn_semibold mt-3 mb-2'>Strategic Planning</h5>
                                    <p className='text-[15px] font-normal text-[#353535] leading-[19.53px] mb-5'>We help organizations develop and implement effective business strategies that are aligned with their objectives and resources.</p>
                                    <div className='flex'>
                                        {/* <button className='bg-primary px-5 py-3 text-[15px] font-semibold font-mn_medium flex justify-between rounded-[7px]'>
                                            <p className='text-white'>Learn more</p>
                                            <div className='pl-3'>
                                                <img src={'/images/arrow-right.svg'} alt='icon' className=' object-cover rounded-[7px]' />
                                            </div>

                                        </button> */}
                                    </div>

                                </div>
                                <div className='h-[350px] bg-[#ECF1FF] p-3 rounded-[15px] w-full flex flex-col justify-between '>
                                    <div className=''>
                                        <img src={isMobile ? '/images/mo22.png' : '/images/s2.svg'} alt='services_1' className='h-[162px] w-full object-cover rounded-[8px]' />
                                    </div>
                                    <h5 className='text-[18px] font-semibold text-primary font-mn_semibold mt-3 mb-2'>Business Transformation</h5>
                                    <p className='text-[15px] font-normal text-[#353535] leading-[19.53px] mb-5'>We provide a range of services to help clients transform their businesses by improving processes, systems, and technologies.</p>
                                    <div className='flex'>
                                        {/* <button className='bg-primary px-5 py-3 text-[15px] font-semibold font-mn_medium flex justify-between rounded-[7px]'>
                                            <p className='text-white'>Learn more</p>
                                            <div className='pl-3'>
                                                <img src={'/images/arrow-right.svg'} alt='icon' className=' object-cover rounded-[7px]' />
                                            </div>

                                        </button> */}
                                    </div>

                                </div>
                                <div className='h-[350px] bg-[#ECF1FF] p-3 rounded-[15px] w-full flex flex-col justify-between '>
                                    <div className=''>
                                        <img src={isMobile ? '/images/mo3.png' : '/images/s3.svg'} alt='services_1' className='h-[162px] w-full object-cover rounded-[8px]' />
                                    </div>
                                    <h5 className='text-[18px] font-semibold text-primary font-mn_semibold mt-3 mb-2'>Performance Improvement</h5>
                                    <p className='text-[15px] font-normal text-[#353535] leading-[19.53px] mb-5'>We analyze client operations to identify areas of inefficiency and provide recommendations for improving performance.</p>
                                    <div className='flex'>
                                        {/* <button className='bg-primary px-5 py-3 text-[15px] font-semibold font-mn_medium flex justify-between rounded-[7px]'>
                                            <p className='text-white'>Learn more</p>
                                            <div className='pl-3'>
                                                <img src={'/images/arrow-right.svg'} alt='icon' className=' object-cover rounded-[7px]' />
                                            </div>

                                        </button> */}
                                    </div>

                                </div>
                                <div className='h-[350px] bg-[#ECF1FF] p-3 rounded-[15px] w-full flex flex-col justify-between '>
                                    <div className=''>
                                        <img src={isMobile ? '/images/mo4.png' : '/images/s4.svg'} alt='services_1' className='h-[162px] w-full object-cover rounded-[8px]' />
                                    </div>
                                    <h5 className='text-[18px] font-semibold text-primary font-mn_semibold mt-3 mb-2'>Innovation and Growth</h5>
                                    <p className='text-[15px] font-normal text-[#353535] leading-[19.53px] mb-5'>We help clients identify and capitalize on <span className='italic text-primary'>growth opportunities</span>. Our approach is tailored to each client's unique needs and culture.</p>
                                    <div className='flex'>
                                        {/* <button className='bg-primary px-5 py-3 text-[15px] font-semibold font-mn_medium flex justify-between rounded-[7px]'>
                                            <p className='text-white'>Learn more</p>
                                            <div className='pl-3'>
                                                <img src={'/images/arrow-right.svg'} alt='icon' className=' object-cover rounded-[7px]' />
                                            </div>

                                        </button> */}
                                    </div>

                                </div>
                            </Fade>


                        </div>
                    </div>


                </div>


            </div>

        </section>

    )
}

export default OurServices