import React from 'react'
import { Fade, Slide, Zoom } from 'react-awesome-reveal'
import { isMobile, isTablet } from 'react-device-detect';

const About = () => {
    return (
        <section className="py-8  sm:py-10 lg:py-16 " id='about-section'>
            <div className="w-11/12 mx-auto">
                <div className="block lg:grid items-center grid-cols-1 lg:grid-cols-12  gap-y-12 gap-20">
                    <div className="col-span-4  ">
                        <Zoom>
                            <div className="w-full">
                                <img className="relative object-cover w-full lg:w-auto mb-5 lg:mb-0" src={isMobile ? '/images/about.png' : "/images/about.svg"} alt="" />
                                ´       </div>
                        </Zoom>


                    </div>

                    <div className="col-span-8">
                        <p className='text-primary text-[13px] font-normal font-dmsans mb-5'>WHAT WE DO</p>
                        <Slide direction='right'>
                            <h2 className=" font-bold leading-[38.82px] lg:leading-[54.59px] text-[#353535] text-[32px] lg:text-[45px]  font-mn_semibold">We collaborate with teams to unlock value and achieve success</h2>

                        </Slide>
                        <Slide direction='right'>
                            <p className=" mt-5 text-[16px] font-normal text-[#353535] font-dmsans md:pb-4 pb-5  border-b border-[#D9D9D9] leading-[20.83px] ">At Bayajidda, we pride ourselves on our African roots and global perspective. Our name pays homage to the legendary figure Bayajidda, a symbol of wisdom, leadership, and problem-solving prowess. Just like Bayajidda, we are committed to helping our clients overcome challenges and achieve their goals with resilience and ingenuity.</p>

                        </Slide>

                        <div className='my-4'>
                            <div className='grid grid-col-1  md:grid-cols-3 gap-10'>
                                <Fade cascade>

                                    <div>
                                        <div className='w-full'>
                                            <img src={'/images/expertise.svg'} alt='icon' className='object-cover ' />
                                        </div>
                                        <h3 className='text-[20px] md:text-[18px] font-semibold font-mn_semibold text-[#071847] my-1'>Expertise</h3>
                                        <p className='text-[#353535] text-[16px] md:text-[14px] leading-[20.83px] font-dmsans font-normal'>Our team brings together a diverse group of experts with deep industry knowledge and a passion for making a difference.</p>

                                    </div>
                                    <div>
                                        <div>
                                            <img src={'/images/excellence.svg'} alt='icon' className='object-cover' />
                                        </div>
                                        <h3 className='text-[20px] md:text-[18px] font-semibold font-mn_semibold text-[#071847] my-1'>Commitment to Excellence</h3>
                                        <p className='text-[#353535] text-[16px] md:text-[14px] leading-[20.83px] font-dmsans font-normal'>We are committed to delivering excellence in everything we do, from the quality of our work to the level of service we provide.</p>

                                    </div>
                                    <div>
                                        <div>
                                            <img src={'/images/global.svg'} alt='icon' className='object-cover' />
                                        </div>
                                        <h3 className='text-[20px] md:text-[18px] font-semibold font-mn_semibold text-[#071847] my-1'>Global Perspective</h3>
                                        <p className='text-[#353535] text-[16px] md:text-[14px] leading-[20.83px] font-dmsans font-normal'>With a network of partners and clients spanning the globe, we offer a truly global perspective that helps drive innovation and growth.</p>

                                    </div>
                                </Fade>

                            </div>

                        </div>
                        {/* <div>
                            <button className='flex-shrink-0 bg-primary text-white rounded-[7px] px-7 py-3  font-medium'>
                                Learn More
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default About