import React, { useState } from 'react'
import CircularProgressbar from '../../component/Progress';
import { Slide, Zoom } from 'react-awesome-reveal';
const Exist = () => {
    const [isChecked, setIsChecked] = useState(false);

    const toggleSwitch = () => {
        setIsChecked(!isChecked);
    };

    return (
        <section className="py-10 bg-white sm:py-16 lg:py-10 ">
            <div className="w-11/12 mx-auto">
                <div className='grid grid-cols-1 lg:grid-cols-12 gap-8 items-center'>
                    <div className='col-span-6'>
                        <Slide direction='left'>

                            <p className='text-primary text-[13px] font-normal font-dmsans mb-5'>WHY WE EXIST?</p>
                            <h2 className=" font-bold leading-[38.82px] lg:leading-[54.59px] text-[#353535] text-[32px] lg:text-[45px]  font-mn_semibold">Exceptional consulting outfit with African roots and expertise</h2>
                        </Slide>

                    </div>


                    <div className='col-span-6'>
                        <Slide direction='right'>

                            <p className="leading-[20.83px] mt-5 text-[16px] font-normal text-[#353535] font-dmsans pb-4  ">Organizations across industries often encounter complex challenges that require objective perspectives and strategic thinking to solve.</p>
                            <p className="leading-[20.83px] mt-5 text-[16px] font-normal text-[#353535] font-dmsans pb-4  ">BCS provides such support to businesses of every size, from small but promising startups, to mid-scale businesses, to large established players, we take a rigorous, hands on approach to supporting you solve problems, unlock growth, and turbocharge your business.</p>

                        </Slide>


                    </div>

                </div>
                <div className="relative inline-flex items-center border border-[#AAC0FF] rounded-[60px] bg-[#21409A12] my-6 w-full lg:w-auto">
                    <button
                        className={`w-full    flex-shrink-1 lg:w-auto focus:outline-none  text-[18px] font-medium py-3 px-3 lg:px-5 font-mn_medium cursor-pointer ${!isChecked ? 'text-white bg-primary !rounded-[60px]' : 'text-primary'}`}
                        onClick={toggleSwitch}
                    >
                        Start-ups
                    </button>
                    <button
                        className={` w-full   flex-shrink-1 lg:w-auto focus:outline-none  text-[18px] font-medium py-3 px-4 lg:px-5 font-mn_medium cursor-pointer ${!isChecked ? 'text-primary' : 'text-white bg-primary rounded-[60px]'}`}
                        onClick={toggleSwitch}
                    >
                        Existing Business
                    </button>
                    <div className={`absolute top-0 left-0 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 hidden ${isChecked ? 'translate-x-full' : ''}`} />
                </div>
                <div className='exist-section min-h-[494px] grid grid-cols-1 lg:grid-cols-2 gap-12 items-center p-8 lg:p-16 rounded-[15px]'>

                    <div className=''>
                        <h3 className='text-primary font-semibold font-mn_semibold text-[20px] mb-1'>{!isChecked ? 'For Start-ups' : 'Existing Businesses'}</h3>
                        {
                            !isChecked ? <p className='text-[18px] font-dm_sans text-[#353535] leading-[24px] font-normal'>

                                Nigeria, home to 5 of Africa’s 7 unicorns, is a leading hub for entrepreneurship in Africa, with over $400 million flowing in as startup funding in 2023.

                                However, <span className='font-bold'>60% of startups in Nigeria fail within the first 5 years of operation</span>, owing in part to a lack of structure and management, lack of market intelligence, and poor strategy
                                <br className='mb-2' />

                                BCS works with startups of different sizes on market research, putting together and executing on winning strategies, and attracting startup funding.
                                {/* Nigeria is the leading hub for entrepreneurship in Africa. According to the 2023 Global Startup Ecosystem Index, the country’s startup scene is a leader in West Africa and ranks 64th globally.
                                Statista reported that the number of startups in Nigeria surpassed 3,360 in 2022. However, data shows that only 39% of startup in Nigeria survive within 3 to 5 years of starting. Some of the causes for failure include faulty business plans, poor assessment of the market, mismanagement, lack of focus etc. */}
                            </p> :
                                <p className='text-[18px] font-dm_sans text-[#353535] leading-[24px] font-normal'>

                                    One major challenge for existing businesses in Nigeria is continuation – as <span className='font-bold'>only a quarter of family businesses in Nigeria make it to the 3rd generation.</span> Another is growth, with less than a quarter of such businesses growing through optimization and innovation.
                                    <br className='mb-2' />
                                    BCS works with existing businesses to advise on strategy, leadership and succession planning, operations, and business and management optimization.
                                    {/* Nigeria is the leading hub for entrepreneurship in Africa. According to the 2023 Global Startup Ecosystem Index, the country’s startup scene is a leader in West Africa and ranks 64th globally.
                               Statista reported that the number of startups in Nigeria surpassed 3,360 in 2022. However, data shows that only 39% of startup in Nigeria survive within 3 to 5 years of starting. Some of the causes for failure include faulty business plans, poor assessment of the market, mismanagement, lack of focus etc. */}
                                </p>
                        }
                    </div>
                    {/* <Zoom> */}
                    <div className='flex items-center w-full bg-danger justify-center'>
                        <CircularProgressbar clockwise={!isChecked ? false : true} value={!isChecked ? 65 : 75}  >
                            {
                                !isChecked ?
                                    <p className='text-[#545454] font-normal font-dm_sans leading-[18.51px] text-center inline-flex text-[14.4px] w-[75%]'>
                                        65% of startups in Nigeria fail within the first 5 years of existence
                                    </p> :
                                    <p className='text-[#545454] font-normal font-dm_sans leading-[18.51px] text-center  text-[14.4px] w-[75%]'>
                                        75% of Nigerian family businesses 
                                        <span className='font-bold'> do not survive </span> 
                                        past the 2nd generation.
                                    </p>
                            }


                        </CircularProgressbar>

                    </div>
                    {/* </Zoom> */}


                </div>


            </div>

        </section>
    )
}

export default Exist