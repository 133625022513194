import React from 'react'
import { Bounce, Fade } from 'react-awesome-reveal'

const Clients = () => {
    return (
        <section className="py-10 bg-[#F8F8F8] sm:py-16 lg:py-10">
            <div className="w-11/12 mx-auto">

                <div className="">
                    <Bounce>
                        <p className='text-primary text-[13px] font-normal font-dmsans mb-5 text-center hidden'>JOIN OVER 3,000+ COMPANIES</p>
                        <h2 className=" font-bold leading-[38.82px] md:leading-[54.59px] text-[#353535] text-[32px] md:text-[45px]  font-mn_semibold text-center mx-auto md:w-4/5 mb-16">The BCS team brings deep experience from leading Global brands</h2>
                    </Bounce>


                    <div className='flex items-center gap-6 lg:gap-16 w-full justify-center flex-wrap'>
                        <Fade cascade>
                            <div>
                                <img src={'/images/cl1.svg'} alt='icon' className='object-cover' />
                            </div>
                            <div>
                                <img src={'/images/cl2.svg'} alt='icon' className='object-cover' />
                            </div>
                            <div>
                                <img src={'/images/cl3.svg'} alt='icon' className='object-cover' />
                            </div>
                            <div>
                                <img src={'/images/cl4.svg'} alt='icon' className='object-cover' />
                            </div>
                            <div>
                                <img src={'/images/dangote.png'} alt='icon' className='object-cover w-[113px] h-[71px]' />
                            </div>
                        </Fade>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default Clients