import React from 'react'
import { motion } from 'framer-motion';

import ReactModal from 'react-modal';
import { isMobile, isTablet } from 'react-device-detect';

const CustomModal = (
    { isOpen, toggleOpen, children, className,
        size
    }) => {


    return (
        <ReactModal
            onRequestClose={toggleOpen}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    backgroundBlendMode: 'hard-light',
                    backdropFilter: 'blur(6px)',
                    zIndex: '151',

                },
                content: {
                    width: isMobile ? '100%' : isTablet ? '80%' : '35%',
                    background: 'unset',
                    margin: 'auto',
                    padding: 0,
                    border: 0,
                    borderRadius: 0,
                    top: '0',
                    right: 0,
                    left: 'auto',
                    bottom: 'auto',
                    // margin: 'auto',
                    // transform: 'translate(-50%, -50%)',

                },

            }}

            isOpen={isOpen}
            className={className}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            preventScroll={true}

        >
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: isOpen ? 1 : 0, scale: isOpen ? 1 : 0.5 }}
                transition={{ duration: 0.3 }}
            >
                {children}

            </motion.div>
        </ReactModal >
    )
}

export default CustomModal