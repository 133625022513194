import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Quote from './Quote'
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect'

const Header = () => {
    let pathname = window.location.pathname
    const [openQuote, setOpenQuote] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const toggleQoute = () => {
        setOpenQuote(!openQuote)
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }
    return (
        <div className='flex items-center  justify-between '>
            <NavLink to='/'>
                <img  src={`/images/${isMobile?'BCS.png':'BCS_1.svg'}`} alt='bcs' className='w-[200px] md:w-auto h-[37.92px] md:h-auto' />
            </NavLink>
            <nav className='gap-x-6 items-center hidden md:flex'>
                
                <NavLink to='/services'

                    className={
                        isActive =>
                            'transition-all ease-in text-[#CAD8FF] text-[15px] font-medium pb-2 text-[#CAD8FF] hover:border-b-2 hover:border-white' + (pathname === '/services' ? " border-b-2 border-white text-white" : "")
                    }
                    end
                >
                    Our services
                </NavLink>
                <NavLink to='/contact-us' className={
                    isActive =>
                        'transition-all ease-in text-[#CAD8FF] text-[15px] font-medium pb-2 text-[#CAD8FF] hover:border-b-2 hover:border-white' + (pathname === '/contact-us' ? " border-b-2 border-white text-white" : "")
                }
                    end
                >
                    Contact us
                </NavLink>
                <button className='bg-white text-primary rounded-[7px] px-5 py-3 font-medium' onClick={toggleQoute}>
                    Get Quote
                </button>

            </nav>
            <div className='md:hidden relative cursor-pointer' onClick={toggleMenu}>
                <motion.div
                    initial={{ opacity: 0, rotate: showMenu ? 45 : 0 }}
                    animate={{ opacity: 1, rotate: showMenu ? 90 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <img src={showMenu ? '/images/closeMenu.svg' : '/images/menu.svg'} alt='icon' className='transition-all delay-100 object-cover' />
                </motion.div>
                <motion.div
                    initial={{ x: -200 }}
                    animate={{ x: showMenu ? 0 : -200 }}
                    transition={{ duration: 0.5 }}
                >
                    {
                        showMenu &&

                        <div className='flex flex-col absolute bg-white  w-[200px] rounded-[5px] shadow-[0px 4px 14.2px 0px #00000066] top-[120%] right-0 z-[99]'>
                            <NavLink to='/services'

                                onClick={toggleMenu}
                                className={

                                    'transition-all ease-in text-[#353535] text-[15px] font-medium font-mn_medium  border-b-2 border-[#EEEEEE] p-3'
                                }
                                end
                            >
                                Our services
                            </NavLink>
                            <NavLink
                                onClick={toggleMenu}
                                to='/contact-us'
                                className={

                                    'transition-all ease-in  text-[15px] font-medium font-mn_medium text-[#353535] border-b-2 border-[#EEEEEE] p-3'
                                }
                                end
                            >
                                Contact us
                            </NavLink>
                            <div className='p-3'>
                                <button className='bg-primary text-white rounded-[7px] px-5 py-3 font-medium w-full' onClick={toggleQoute}>
                                    Get Quote
                                </button>
                            </div>
                        </div>
                    }
                </motion.div>




            </div>

            <Quote
                openQoute={openQuote}
                toggleQuote={toggleQoute}
            />

        </div>
    )
}

export default Header