import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'
import { isMobile } from 'react-device-detect'

const Planning = () => {


    return (
        <section className="py-10 bg-white sm:py-16 lg:py-10">
            <div className="w-11/12 mx-auto">
                <div className='lg:grid grid-cols-12 gap-8 items-center mb-10'>
                    <div className='col-span-6'>
                        <Slide >
                            <p className='text-primary text-[13px] font-normal font-dmsans mb-5'>STATEGIC PLANNING</p>
                            <h2 className=" font-bold md:leading-[54.59px] text-[#353535] leading-[38.82px] text-[32px] md:text-[45px]  font-mn_semibold lg:w-[80%]">Strategic Business Solutions Tailored to Your Vision</h2>

                        </Slide>

                    </div>
                    <div className='col-span-6'>
                        <Slide direction='right'>
                            <p className="leading-[20.83px]  mt-5 text-[16px] font-normal text-[#353535] font-dmsans pb-4  ">We help organizations develop and implement effective business strategies that are aligned with their objectives and resources. Our approach is tailored to the client's unique needs, and we work closely with them to ensure their vision is realized. We provide services ranging from developing entry strategies, market trend analysis, competitive positioning, value proposition, financial modeling, etc. </p>

                        </Slide>



                    </div>
                </div>
                <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4 w-full'>
                    <Fade cascade>
                        <div className=' w-full  justify-between '>
                            <div className=''>
                                <img src={isMobile ? 'images/mo8.png' : '/images/p1.svg'} alt='services_1' className='h-[228px] w-full object-cover rounded-[8px]' />
                            </div>
                            <h5 className='text-[18px] font-medium text-[#071847] font-mn_medium mt-6 mb-1'>Market Entry Strategy</h5>
                            <p className='text-[14px] font-normal text-[#353535] leading-[18.23px] '>Whether you are an existing organization looking to diversify or a startup the employment of well-defined market entry strategies cannot be over-emphasized. We provide an optimal approach to rolling out products or services. By understanding the uniqueness of the new product or service in a defined target market and the resources at the disposal of your organization we can design effective strategies that ensure sustainable market penetration.</p>


                        </div>
                        <div className=' w-full  justify-between '>
                            <div className=''>
                                <img src={isMobile ? 'images/mo9.png' : '/images/p2.svg'} alt='services_1' className='h-[228px] w-full object-cover rounded-[8px]' />
                            </div>
                            <h5 className='text-[18px] font-medium text-[#071847] font-mn_medium mt-6 mb-1'>Competitive Positioning</h5>
                            <p className='text-[14px] font-normal text-[#353535] leading-[18.23px] '>Highlighting what makes your product unique helps you to attract and keep more customers, which leads to higher revenue and a bigger share of the market. When your product development and marketing are aligned, your brand resonates with the customers and strengthens your market position. We focus on your target audience by understanding their needs, which will aid in creating effective marketing strategies that expand your market reach and brand loyalty.</p>


                        </div>
                        <div className=' w-full  justify-between '>
                            <div className=''>
                                <img src={isMobile ? 'images/mo10.png' : '/images/p3.svg'} alt='services_1' className='h-[228px] w-full object-cover rounded-[8px]' />
                            </div>
                            <h5 className='text-[18px] font-medium text-[#071847] font-mn_medium mt-6 mb-1'>Value Proposition</h5>
                            <p className='text-[14px] font-normal text-[#353535] leading-[18.23px] '>Your organization’s value proposition is the core of your competitive advantage. It clearly articulates why someone would want to buy your product instead of a competitor.
                                Value proposition is more than a product description, it is a promise of value stated by your company that summarizes how the benefit of the company’s product or service will be delivered, experienced, and acquired. This makes it one of the most important conversion factors.</p>


                        </div>
                        <div className=' w-full  justify-between '>
                            <div className=''>
                                <img src={isMobile ? 'images/mo11.png' : '/images/p4.svg'} alt='services_1' className='h-[228px] w-full object-cover rounded-[8px]' />
                            </div>
                            <h5 className='text-[18px] font-medium text-[#071847] font-mn_medium mt-6 mb-1'>Financial Modeling</h5>
                            <p className='text-[14px] font-normal text-[#353535] leading-[18.23px] '>Our approach is meticulous, as we carefully construct models with precise inputs and thoughtful assumptions. This results in comprehensive numerical representations of your company's operations across time. With our custom-made financial models, you'll have powerful decision-making tools at your disposal. They can estimate costs, and project profits, and help you assess the potential impact of different scenarios.</p>


                        </div>
                    </Fade>



                </div>
            </div>

        </section>
    )
}

export default Planning