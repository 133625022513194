import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'
import { isMobile } from 'react-device-detect'
const Performance = () => {
    return (
        <section className="py-10 bg-white sm:py-16 lg:py-24">
            <div className="w-11/12 mx-auto">
                <div className="grid items-center grid-cols-1 lg:grid-cols-12  gap-y-14 ">
                    <div className="col-span-5 lg:mb-12 ">
                        <Slide>
                            <div className="w-full lg:w-auto">
                                <img className="relative object-cover w-full lg:w-auto" src={isMobile ? '/images/mo14.png' : "/images/performance.svg"} alt="" />
                            </div>
                        </Slide>


                    </div>

                    <div className="col-span-7">
                        <Slide direction='right'>
                            <p className='text-primary text-[13px] font-normal font-dmsans mb-5 uppercase tracking-widest'>Performance Improvement</p>
                            <h2 className=" font-bold text-[32px] leading-[38.82px] md:leading-[54.59px] text-[#353535] md:text-[45px]  font-mn_semibold">Enhancing Efficiency and Sustainability</h2>
                            <p className=" mt-5 text-[16px] font-normal text-[#353535] font-dmsans pb-4  ">We analyze client operations to identify areas of inefficiency and provide recommendations for improving performance. We work with clients to implement these recommendations and ensure they achieve sustainable results. We provide services in this category ranging from process optimization, operations research, cost reduction strategies, etc.</p>
                        </Slide>


                        <div className='my-4 grid md:grid-cols-2 gap-5'>
                            <Fade cascade>

                                <div className='h-[2 bg-primary p-5 rounded-[11px]'>
                                    <div>
                                        <img src={'/images/e1.svg'} alt='icon' className='h-[33px] w-[33px] mb-2' />
                                    </div>
                                    <h3 className='text-white text-[18px] font-semibold font-mn_semibold mb-2'>Process optimization</h3>
                                    <p className='text-[#B6D5FF] text-[14px] font-normal font-dmsans leading-[18.23px]'>BCS excels at analyzing your existing processes, identifying inefficiencies, and implementing targeted improvements. By streamlining workflows and eliminating bottlenecks, we help your organization operate at its full potential, boosting productivity and overall performance.</p>
                                </div>

                                <div className='h bg-white p-5 rounded-[11px] border border-[#B6D5FF]'>
                                    <div>
                                        <img src={'/images/e2.svg'} alt='icon' className='h-[33px] w-[33px] mb-2' />
                                    </div>
                                    <h3 className='text-[#041033] text-[18px] font-semibold font-mn_semibold mb-2'>Cost reduction strategy</h3>
                                    <p className='text-[#353535] text-[14px] font-normal font-dmsans leading-[18.23px]'>Cost efficiency is paramount in today's competitive landscape, and we have the expertise to develop tailored cost-reduction strategies for your organization. We analyze cost structures, identify areas for improvement, and implement cost-saving measures without compromising quality or customer satisfaction.</p>
                                </div>
                            </Fade>

                        </div>

                    </div>

                </div>
                <div className='grid lg:grid-cols-2 w-full rounded-[15px] overflow-hidden items-center lg:h-[541px] mt-10 bg-[#F2F5FF] gap-10 p-6 md:p-12 lg:p-0'>
                    <div className='lg:pl-12'>
                        <Slide>

                            <p className='text-primary text-[13px] font-normal font-dmsans mb-5 uppercase tracking-widest'>Innovation and Growth</p>
                            <h2 className="  lg:leading-[54.59px] text-[#353535] text-[32px] leading-[38.82px] lg:text-[45px]  font-mn_semibold">Unlocking Growth: Tailored Innovation Solutions for Every Client</h2>
                            <p className=" mt-5 text-[16px] font-normal text-[#353535] font-dmsans pb-4  ">We help clients identify and capitalize on growth opportunities, whether through new products or services, entering new markets, or leveraging emerging technologies. Our approach to innovation is tailored to each client's unique needs and culture.</p>
                        </Slide>

                    </div>
                    <div className='h-full w-full'>
                        <Slide direction='right'>
                            <img src={isMobile ? '/images/mo15.png' : '/images/i1.svg'} alt='icon' className='h-full w-full object-cover' />

                        </Slide>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Performance