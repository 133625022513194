import React from 'react'
import { Fade, Slide } from 'react-awesome-reveal'

const Business = () => {
    return (
        <section className="py-10 bg-[#F2F5FF] sm:py-16 lg:py-12">
            <div className="w-11/12 mx-auto">
                <div className="mb-14">

                    <Fade cascade>
                        <p className='text-primary text-[13px] font-normal font-dmsans mb-6 text-center'>Business Transformation</p>
                        <h2 className=" font-semibold text-[32px] leading-[32px] lg:leading-[54.59px] text-[#353535] lg:text-[45px]  font-mn_semibold text-center mx-auto w-[80%] mb-6">Empowering Business Transformation: Tailored Service for Sustainable Growth</h2>
                        <p className='text-[#353535] md:text-[16px] leading-[22.83px] text-[13px] font-normal font-dmsans mb-5 text-center lg:w-[80%] text-center mx-auto '>Our solutions are designed to help organizations achieve sustainable results and enable them to operate more effectively in a rapidly changing business environment. We provide services ranging from identifying and assessing digital opportunities, organizational restructuring, sustainability management, etc.</p>
                    </Fade>
                </div>


                <div className='grid md:grid-cols-2 gap-5'>
                    <Slide>
                        <div className='h-[480px] lg:h-[420px] rounded-[11px] relative overflow-hidden  business-card-1'>
                            <div className='absolute business-card_child h-auto lg:h-[160px] bottom-0 left-0 right-0 p-6  text-white'>
                                <h3 className='z-[99] text-[18px] font-semibold font-mn_semibold mb-2'>Identifying and Assessing Digital Opportunities</h3>
                                <p className='z-[99] text-[14px] font-normal leading-[21px]'>Our expert team excels in analyzing your business landscape to pinpoint digital opportunities that align with your goals. We meticulously evaluate market trends and potential innovations, providing you with strategic insights to stay ahead in the digital realm.</p>

                            </div>

                        </div>
                    </Slide>
                    <Slide direction='right'>
                        <div className='h-[480px] lg:h-[420px] rounded-[11px] relative overflow-hidden  business-card-2'>
                            <div className='absolute business-card_child h-auto lg:h-[160px] bottom-0 left-0 right-0 p-6  text-white'>
                                <h3 className='z-[99] text-[18px] font-semibold font-mn_semibold mb-2'>Organizational Restructuring</h3>
                                <p className='z-[99] text-[14px] font-normal leading-[18.23px]'>With a focus on optimizing efficiency and productivity, we offer comprehensive organizational restructuring services. Our tailored solutions cater to your unique needs, empowering your company to adapt swiftly to changing market demands and drive sustainable growth.</p>

                            </div>


                        </div>
                    </Slide>



                </div>
            </div>
        </section>
    )
}

export default Business