import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Services from './pages/Services'
import Footer from './Layout/Footer'



const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/contact-us' element={<Contact />} />
                <Route exact path='/services' element={<Services />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router
