import React, { useEffect } from 'react'

import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import ContactForm from './Form'
import { Slide, Zoom } from 'react-awesome-reveal'
import { isMobile } from 'react-device-detect'
const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className=' bg-[#444] bg-primary py-8 hero-section_contact '>
        <div className='w-11/12 mx-auto '>
          <Header />
          <div className='h-auto flex items-center py-24'>
            <div className='block lg:grid grid-cols-12 items-center justify-between gap-'>
              <div className='col-span-7'>
                <Slide cascade triggerOnce>
                  <p className='text-[13px] leading-[15.62px] md:leading-[16.93px] font-normal font-dmsans text-white  tracking-widest'>CONTACT US</p>
                  <h3 className='text-[39px] leading-[46.62px] md:text-[60px] font-semibold md:leading-[71.18px] hero-text_contact font-mn_semibold mt-5 mb-6 lg:w-[90%]'>We look forward to hearing from you and assisting you with your consulting needs</h3>
                  <p className='text-[16px] md:text-[18px] leading-[20.83px] md:leading-[23.44px]  font-normal font-dmsans text-white leading-[23.44px] mb-16 lg:mb-0 lg:w-[90%]'>We're here to help you navigate challenges and achieve your goals. Whether you have questions about our services, want to discuss a potential project, or simply need more information, we're eager to hear from you.</p>
                </Slide>
              </div>
              <div className='col-span-5   w-full'>
                <div className=''>
                  <Zoom triggerOnce>
                    <img src={isMobile ? '/images/mo16.png' : '/images/contact_bg.svg'} alt='hero' className='w-full h-full object-cover' />
                  </Zoom>

                </div>
              </div>

            </div>


          </div>
        </div>

      </div >
      <ContactForm />

      <Footer />
    </>

  )
}

export default Contactus