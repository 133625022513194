import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import VisibilitySensor from "react-visibility-sensor";


import React from 'react'

const CircularProgressbarW = ({ text, value, clockwise ,children}) => {
    return (
        <VisibilitySensor>
            {({ isVisible }) => {
                const percentage = isVisible ? value : 0;
                return (
                    <CircularProgressbarWithChildren value={percentage}
                        counterClockwise={!clockwise ? true : false}
                        styles={buildStyles({

                            strokeLinecap: 'round',

                            // Text size
                            textSize: '16px',

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#21409A`,
                            textColor: '#f88',
                            trailColor: '#D3DFFF',
                            backgroundColor: '#3e98c7',
                        })}
                    >
                        <h3 className='text-primary font-bold font-mn_semibold text-[56.37px] leading-[68.38px]'>{percentage}%</h3>
                        {children}
                    </CircularProgressbarWithChildren>
                );
            }}
        </VisibilitySensor>

    )
}

export default CircularProgressbarW