import React, { useEffect } from 'react'
import About from './About'
import Exist from './Exist'
import OurServices from './OurServices'
import OurWork from './OurWork'
import Clients from './Client'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import { Fade, Slide, Zoom } from 'react-awesome-reveal'
import { isMobile, isTablet } from 'react-device-detect';
import { motion } from "framer-motion";


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const scrollToHash = () => {
            const hash = window.location.hash;
            if (hash) {
                const aboutSection = document.getElementById('about-section')
                aboutSection.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        };
        scrollToHash();
    }, []);

    return (
        <>

            <div className='bg-[#444] bg-primary py-8 hero-section lg:h-screen'>
                <div className='w-11/12 mx-auto'>
                    <Header />

                    <div className='h-auto flex items-center py-16  '>
                        <div className='block lg:grid grid-cols-12 items-center '>
                            <div className='col-span-7 mb-12 lg:mb-0 lg:w-[95%]'>
                                <Slide cascade triggerOnce>
                                    <p className='text-[13px] leading-[15.62px] md:leading-[16.93px] font-normal font-dmsans text-white  '>WELCOME TO BAYAJIDDA CONSULTING SERVICES</p>
                                    <h3 className='text-[39px] leading-[46.62px] md:text-[60px] font-semibold md:leading-[80px] hero-text my-2'>Transform Your Business With Our Expert Consulting Services</h3>
                                    <p className='text-[16px] md:text-[18px] leading-[20.83px] md:leading-[23.44px] font-normal font-dmsans text-white  mb-7 md:w-[75%]'>We believe in the transformational power of strategy and analytical decision making in unlocking growth in the dynamic African market.</p>
                                </Slide>
                                <Zoom triggerOnce>
                                    <div className='bg-white flex p-1 justify-between pl-2 md:pl-5 pr-1 rounded-[11px] md:w-[75%] w-full '>
                                        <input className=' sm:w-[50%] md:w-3/5 inline-flex flex-shrink-0 bg-transparent placeholder:text-[#a4a4a4] font-normal placeholder:text-[16px] placeholder:font-normal outline-none border-none' placeholder='Enter your email address' />
                                        <button className='flex-shrink-0 bg-primary text-white rounded-[7px] px-2 md:px-4 py-3  font-normal text-[13px] md:text-[16px]'>
                                            Book an Appointment
                                        </button>
                                    </div>
                                </Zoom>



                            </div>
                            <div className='col-span-5   w-full'>
                                {/* <motion.div
                                    animate={{
                                        rotate: -720, 
                                        scale: [1, 1.05, 1], 
                                    }}
                                    transition={{
                                        duration: 10,
                                        ease: "linear",
                                        repeat: Infinity,
                                    }}
                                > */}
                                <Zoom triggerOnce>
                                    <div className=''>
                                        <img src={isMobile ? '/images/hero_img.png' : '/images/hero_img.png'} alt='hero' className='w-full h-full object-cover' />
                                    </div>
                                </Zoom>
                                {/* </motion.div> */}


                            </div>

                        </div>


                    </div>
                </div>


            </div>
            <About />
            <Exist />
            <OurServices />
            <OurWork />
            <Clients />
            <Footer />
        </>

    )
}

export default Home