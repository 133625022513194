import React, { useEffect } from 'react'

import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Planning from './Planning'
import Business from './Business'
import Performance from './Performance'
import { NavLink } from 'react-router-dom'
import { Slide, Zoom } from 'react-awesome-reveal'
import { isMobile } from 'react-device-detect';



const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, [])
  return (
    <>
      <div className='bg-[#444] bg-primary py-8 hero-section '>
        <div className='w-11/12 mx-auto'>
          <Header />

          <div className='h-auto flex items-center py-24 '>
            <div className='block lg:grid grid-cols-12 items-center  items-center justify-between gap-14'>
              <div className='col-span-7  mb-5 lg:mb-0'>
                <Slide cascade triggerOnce>
                  <p className='text-[13px] leading-[15.62px] md:leading-[16.93px] font-normal font-dmsans text-white  '>OUR SERVICES</p>
                  <h3 className='text-[39px] leading-[46.62px] md:text-[60px] font-semibold md:leading-[71.18px] hero-text my-4'>Our Tailored Solutions and Expert Insights Empower Businesses</h3>
                  <p className='text-[16px] md:text-[18px] leading-[20.83px] md:leading-[23.44px] font-normal font-dmsans text-white leading-[23.44px]  mb-16'>We recognize the significance of effective succession planning and offer reliable consultancy services to address this vital aspect. We overcome these challenges and build a strong foundation for sustained growth and continuity.</p>
                  <div className='mb-10 lg:mb-0 '>
                    <NavLink to='/contact-us' className='flex-shrink-0 bg-white text-primary rounded-[7px] px-5 py-4  font-medium'>
                      Contact us Today
                    </NavLink>
                  </div>
                </Slide>


              </div>
              <div className='col-span-5   w-full'>
                <Zoom triggerOnce>
                  <div className=''>
                    <img src={isMobile ? '/images/mo5.png' : '/images/services_bg.svg'} alt='hero' className='w-full h-full object-cover' />
                  </div>
                </Zoom>
              </div>

            </div>


          </div>
        </div>


      </div>
      <Planning />
      <Business />
      <Performance />

      <Footer />
    </>
  )
}

export default Services