import React from 'react'
import CustomModal from '../component/Modal'

const Quote = ({ openQoute, toggleQuote }) => {
    return (
        <CustomModal
            isOpen={openQoute}
            toggleOpen={toggleQuote}
        >
            <form className="border border-[#CBCBCB] px-8 pb-8 pt-6  bg-white rounded-0 h-screen flex flex-col  justify-center">
                <div className='flex justify-end cursor-pointer' onClick={toggleQuote}>
                    <img src={'/images/close.svg'} alt='icon' />
                </div>
                <div className='pb-3 border-b border-dashed border-[#D7D7D7] mb-5'>
                    <h3 className='text-primary font-semibold text-[20px] font-mn_semibold'>Get Quote</h3>
                </div>
                <div className='flex flex-col gap-y-4'>
                    <div className=''>
                        <label className='text-[#868686] font-medium text-[14px]  w-full' for='full_name'>Full name</label>
                        <input id='full_name' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary' placeholder='Full Name' />
                    </div>
                    <div className='grid grid-cols-2  gap-2'>
                        <div className=''>
                            <label className='text-[#868686] font-medium text-[14px]  w-full' for='email'>Email Address</label>
                            <input id='email' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary' placeholder='account@email.com' />
                        </div>
                        <div>
                            <label className='text-[#868686] font-medium text-[14px]  w-full' for='contact'>Contact Number</label>
                            <input id='contact' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary' placeholder='Phone Number' />
                        </div>

                    </div>

                    <div className=''>
                        <label className='text-[#868686] font-medium text-[14px]  w-full' for='company'>Company Name (Optional)</label>
                        <input id='company' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary' placeholder='Name of company' />
                    </div>
                    <div className=''>
                        <label className='text-[#868686] font-medium text-[14px]  w-full' for='option'>Type of Service</label>
                        <div className='border border-[#CBCBCB] rounded-[10px] p-3 mt-1'>
                            <select id='option' className='border-none rounded-[10px] placeholder:text-[14px] placeholder:font-normal  w-full mt-1 focus:outline-none focus:border-primary' placeholder='Name of company' >
                                <option className='text-[14px] font-normal !text-[#868686]'>Select Option</option>
                            </select>
                        </div>

                    </div>

                    <div className=''>
                        <label className='text-[#868686] font-medium text-[14px]  w-full' for='message'>Message</label>
                        <textarea rows={7} id='message' className='border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary' placeholder='Name of company' />
                    </div>
                    <div>
                        <button className='flex-shrink-0 bg-primary text-white rounded-[7px] px-5 py-3  font-medium'>
                            Send Message
                        </button>
                    </div>
                </div>


            </form>


        </CustomModal>
    )
}

export default Quote