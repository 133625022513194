import React from 'react'
import { NavLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'


const Footer = () => {
  const scrollIntoView = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div className='bg-[#071847] pt-16 pb-14'>
      <div className='w-11/12 mx-auto'>
        <div className='mb-10'>
          <img src={`/images/${isMobile?'BCS.png':'BCS_1.svg'}`} alt='bcs' className='w-[200px] md:w-auto h-[37.92px] md:h-auto' />
        </div>
        <div className='lg:grid grid-cols-2 items-center justify-between '>
          <p className='text-[15px] font-normal text-[#CAD8FF] font-dmsans leading-[25px] '>
            Bayajidda Consulting Services Ltd is a strategy consulting firm that support clients achieve their business objectives by providing customized solutions that are practical, sustainable, and focused on long-term growth.
          </p>
          <div>
            <div className='flex-shrink-0 justify-center lg:justify-end flex flex-wrap my-10 lg:my-0' >
              <nav className='flex gap-x-6 lg:gap-x-16 items-center'>
                <NavLink to='/' className={'text-white text-[16px] font-semibold font-mn_semibold text-[#CAD8FF]'}
                  onClick={scrollIntoView}
                >
                  Home
                </NavLink>
                <NavLink to='/#about' className={'text-white text-[16px] font-semibold font-mn_semibold text-[#CAD8FF]'}
                  onClick={
                    () => {
                      const aboutSection = document.getElementById('about-section')
                      if (aboutSection) {
                        aboutSection.scrollIntoView({
                          behavior: 'smooth'
                        })
                      }
                    }
                  }
                >
                  About BCS
                </NavLink>
                <NavLink to='/services' className={'text-white text-[16px] font-semibold font-mn_semibold text-[#CAD8FF]'}
                  onClick={scrollIntoView}
                >
                  Services
                </NavLink>
                <NavLink to='/contact-us' className={'text-white text-[16px] font-semibold font-mn_semibold text-[#CAD8FF]'}
                  onClick={scrollIntoView}
                >
                  Contact us
                </NavLink>


              </nav>
            </div>
            <div className='flex gap-3 mt-5 justify-center lg:justify-end  hidden'>
              <div className='flex-shrink-0'>
                <img src={'/images/social_8.svg'} alt='icon' className='object-cover' />
              </div>
              <div className='flex-shrink-0'>
                <img src={'/images/social_9.svg'} alt='icon' className='object-cover' />
              </div>
              <div className='flex-shrink-0'>
                <img src={'/images/social_0.svg'} alt='icon' className='object-cover' />
              </div>
              <div className='flex-shrink-0'>
                <img src={'/images/social_1.svg'} alt='icon' className='object-cover' />
              </div>
              <div className='flex-shrink-0'>
                <img src={'/images/social_2.svg'} alt='icon' className='object-cover' />
              </div>
              <div className='flex-shrink-0'>
                <img src={'/images/social_3.svg'} alt='icon' className='object-cover' />
              </div>
              <div className='flex-shrink-0'>
                <img src={'/images/social_4.svg'} alt='icon' className='object-cover' />
              </div>

            </div>
          </div>



        </div>
        <div className='my-10 border-b border-white w-full' />
        <div className='flex gap-x-1 items-center justify-center'>
          <div className='hidden md:block'>
            <img src={'/images/copyright.svg'} alt='icon' />
          </div>
          <p className='text-[#CAD8FF] text-[13px] text-center md:text-[15px] font-normal'>Bayajidda Consulting Services Copyright 2024 - All rights reserved</p>

        </div>

      </div>

    </div>
  )
}

export default Footer